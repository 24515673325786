import React from 'react'
import { useLangContext } from '../context/lang.context'
// import { useMatchMedia } from '../utils/useMatchMedia'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Headline from '../components/Headline'
import { s } from '../style'

const Gallery = ({ images, content, inner }) => {
  const { lang } = useLangContext()
  const [index, setIndex] = React.useState(-1)
  // const isDesktopResolution = useMatchMedia('(min-width:1200px)', true)

  const imagesArray = images.map((image) => {
    return {
      src: image.url ? image.url : image.publicURL,
      height: image.height ?  image.height : 720,
      width: image.width ? image.width : 1280,
    }
  })
  
  const handleImageClick = (index) => {
    setIndex(index)
  }

  return (
    <div css={[sContainer, inner && sContainerInner]}>
      {content && <Headline h={2} title={content.title[lang]} large />}
      <div css={[sGalleryGrid, inner && sGalleryGridInner]}>
        {images.map((image, id) => (
          <div
            key={id}
            css={sImageWrapper}
            onClick={() => handleImageClick(id)}>
            {!inner && (
              <GatsbyImage image={getImage(image.childImageSharp)} alt='' />
            )}
            {inner && <GatsbyImage image={getImage(image)} alt='' />}
          </div>
        ))}
      </div>
      <Lightbox
        index={index}
        open={index >= 0}
        close={() => {
          setIndex(-1)
        }}
        slides={imagesArray}
      />
    </div>
  )
}

const sContainer = {
  width: '100vw',
  maxWidth: 1500,
  margin: 'auto',
  flexWrap: 'wrap',
  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
}

const sContainerInner = {
  width: '100%',
  maxWidth: 'unset',
  margin: '0 0 4em',
  padding: '0',
  [s.md]: {
    padding: '0',
  },
}

const sGalleryGrid = {
  paddingTop: '1rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '50px 40px',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '6px',
  },
}

const sGalleryGridInner = {
  paddingTop: '0',
  gridGap: '25px',
  [s.sm_down]: {
    gridGap: '1rem',
  },
  [s.xs]: {
    gridGap: '1rem',
  },
}

const sImageWrapper = {
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '56.25%',
  overflow: 'hidden',
  borderRadius: '1rem',
  cursor: 'pointer',
  '.gatsby-image-wrapper': {
    position: 'absolute',
    width: '100%',
    height: '100%',

    img: {
      transition: 'transform .3s ease-in-out',
    },
    '&:hover': {
      img: {
        transform: 'scale(1.05)',
      },
    },
  },
  [s.sm_down]: {
    paddingBottom: '66%',
    '&:nth-of-type(3n - 2)': {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
}

export default Gallery
