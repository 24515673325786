import React from 'react'
import { sBlogWrapper } from './BlogSearch'
import Grid from '../../components/Grid'
import BlogTileItem from './BlogTileItem'

const BlogTile = ({ data, extraCss, related }) => {
  return (
    <>
      {related ? (
        <BlogTileItem data={data} />
      ) : (
        <Grid xs={12} sm={6} md={4} item css={[sBlogWrapper, extraCss]}>
          <BlogTileItem data={data} />
        </Grid>
      )}
    </>
  )
}

export default BlogTile
