import React from 'react'
import { sBlog, sDescription, sImage, sTitle } from './BlogSearch'
import { string_to_slug } from '../../utils'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import alt from '../../content/alt'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

const BlogTileItem = ({ data }) => {
  const { blog_slug, title, image, description } = data

  return (
    <>
      <div
        onClick={() =>
          navigate(`/blog/${blog_slug ? blog_slug : string_to_slug(title)}/`)
        }
        onKeyPress={() =>
          navigate(`/blog/${blog_slug ? blog_slug : string_to_slug(title)}/`)
        }
        tabIndex={0}
        role='button'
        css={[sBlog]}>
        <div css={sImage}>
          {image && (
            <GatsbyImage
              style={sImage.image}
              image={getImage(image)}
              alt={title + alt}
              // imgStyle={{ objectPosition: 'center top' }}
            />
          )}
        </div>
        <Link to={`/blog/${blog_slug ? blog_slug : string_to_slug(title)}/`}>
          <h3 css={sTitle}>{title}</h3>
        </Link>
        <p css={sDescription}>{description}</p>
      </div>
    </>
  )
}

export default BlogTileItem
