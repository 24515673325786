import React, { useState, useEffect } from 'react'
import { s, colors, alpha, sInput } from '../../style'
import { useLocation, navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import Grid from '../../components/Grid'

import H from '../../components/H'
import queryString from 'query-string'
import { useLangContext } from '../../context/lang.context'
import Section from '../../components/Section'
import BlogTile from './BlogTile'

const content = {
  noContent: {
    pl: 'Nie odnaleziono artykułów w języku polskim w tej kategorii o podanej frazie. Sprawdź za jakiś czas!',
    en: 'No articles in English have been found within this category with the given phrase. Check back later!',
  },
  search: {
    pl: 'Wyszukaj artykułu',
    en: 'Search articles',
  },
  searchPlaceholder: {
    pl: 'Wpisz tytuł lub słowa kluczowe oddzielone spacją',
    en: 'Type a title or keywords separated by a space',
  },
  allArticles: {
    pl: 'Wszystkie',
    en: 'All',
  },
  backToAllArticles: {
    pl: 'Pozostałe Artykuły',
    en: 'Back to Articles',
  },
}

const BlogSearch = () => {
  const { lang } = useLangContext()
  const [searchInput, setSearchInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)

  const location = useLocation()
  const params = queryString.parse(location.search)

  const scrollDown = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: window.innerHeight + 96,
        behavior: 'smooth',
      })
    }
  }

  const onValueChange = (event) => {
    event.preventDefault()
    setSearchInput(event.target.value)
    setSelectedCategory(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate(
      `/blog/?${searchInput ? 's=' + searchInput : ''}${
        searchInput && selectedCategory ? '&' : ''
      }${
        selectedCategory
          ? 'category=' +
            selectedCategory
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          : ''
      }`
    )
  }

  const results = useStaticQuery(graphql`
    {
      blogs: allContentfulBlog(
        filter: { blog_slug: { ne: null } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          blog_slug
          blog_categories {
            blog_category
          }
          keywords
          node_locale
          lang {
            slug
          }
          title
          description
          date
          seo_title
          seo_desc
          first_block_text {
            childMarkdownRemark {
              html
            }
          }
          image {
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData(
              width: 640
              quality: 100
              formats: [JPG]
              placeholder: BLURRED
            )
          }
        }
      }
      categories: allContentfulBlogKategoria {
        nodes {
          id
          blog_category
          node_locale
          lang {
            slug
          }
        }
      }
    }
  `)

  const blogs = results.blogs.nodes
  const categories = results.categories.nodes.filter(
    (category) => category.lang.slug === lang
  )

  const blogsExisting = blogs
    .filter((blog) => blog?.blog_category?.blog_category !== null)
    .filter((blog) => blog?.title !== null)
    // .filter((blog) =>
    //   lang === 'pl' ? blog?.node_locale === 'pl' : blog?.node_locale !== 'pl'
    // )
    .filter((blog) =>
      selectedCategory
        ? blog?.blog_categories.find(
            ({ blog_category }) => blog_category === selectedCategory
          )
        : true
    )
    .filter((blog) =>
      searchInput.length > 0
        ? blog.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          blog.keywords?.filter(
            (word) =>
              word.toLowerCase().includes(searchInput.toLowerCase()) ||
              searchInput
                .toLowerCase()
                .split(' ')
                .filter(
                  (searchText) =>
                    searchText && word.toLowerCase().includes(searchText)
                ).length > 0
          ).length > 0 ||
          blog?.description
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          blog?.blog_category?.blog_category
            ?.toLowerCase()
            .includes(searchInput.toLowerCase())
        : true
    )
  // .sort((a, b) => a.date - b.date)

  /* eslint-disable */
  useEffect(() => {
    if (params?.category) {
      const query = params.category.toLowerCase()
      if (
        categories
          .map(({ blog_category }) =>
            blog_category
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) // here it should also remove latin-ext characters
          .includes(query)
      )
        setSelectedCategory(query.charAt(0).toUpperCase() + query.slice(1))
      scrollDown()
    }
  }, [])

  useEffect(() => {
    if (params?.s) {
      if (!searchInput && params?.s) {
        const query = params.s
        setSearchInput(query.split(',').join(' '))
        scrollDown()
      }
    }
  }, [])

  useEffect(() => {
    if (params?.articles === null) scrollDown()
  }, [])
  /* eslint-enable */

  return (
    <Section mobile top pbXL>
      <div itemScope itemType='http://schema.org/WebSite'>
        <meta itemProp='url' content='https://omida.pl/' />
        <form
          itemProp='potentialAction'
          itemScope
          itemType='https://schema.org/SearchAction'
          onSubmit={handleSubmit}
          css={{
            [s.sm_only]: { margin: '0rem 2rem' },
            paddingBottom: '1rem',
            display: 'flex',
            position: 'relative',
          }}>
          <meta
            itemProp='target'
            content='https://omida.pl/blog/?s={search_term_string}'
          />
          <input
            itemProp='query-input'
            required
            onChange={onValueChange}
            name='search_term_string'
            value={searchInput}
            valueName='search_term_string'
            placeholder={content.search[lang]}
            css={[
              sInput,
              {
                maxWidth: 'calc(100vw - 2rem)',
                [s.sm_down]: {
                  marginBottom: 0,
                },
                [s.md]: {
                  marginBottom: 0,
                },
              },
            ]}
          />
          <meta itemprop='valueName' content='q' />

          <input
            value={lang === 'en' ? 'Search' : 'Szukaj'}
            type='submit'
            css={{
              position: 'absolute',
              top: '1.25rem',
              right: '2rem',
              appearance: 'none',
              outline: 'none',
              border: 'none',
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              fontFamily: 'inherit',
              fontWeight: 700,
              opacity: 0.5,
              // transform: 'translateY(-50%)',
            }}
          />
        </form>
        <div css={sCategories}>
          <div
            onClick={() => setSelectedCategory(null)}
            onKeyPress={() => setSelectedCategory(null)}
            tabIndex={0}
            role='button'
            css={[sCategory, selectedCategory === null && sCategory.active]}>
            {content.allArticles[lang]}
          </div>
          {categories
            .filter((category) =>
              lang === 'pl'
                ? category?.node_locale === 'pl'
                : category?.node_locale !== 'pl'
            )
            .sort((a, b) => a.order - b.order)
            .map((category, id) => (
              <div
                key={id}
                onClick={() => setSelectedCategory(category.blog_category)}
                onKeyPress={() => setSelectedCategory(category.blog_category)}
                tabIndex={0}
                role='button'
                css={[
                  sCategory,
                  selectedCategory === category.blog_category &&
                    sCategory.active,
                ]}>
                {category.blog_category}
              </div>
            ))}
        </div>

        <Grid container>
          {blogsExisting.length === 0 && (
            <H extraCss={{ textAlign: 'left', [s.md]: { width: '100%' } }}>
              {content.noContent[lang]}
            </H>
          )}
          {blogsExisting.map((blog, id) => (
            <BlogTile key={id} data={blog} />
          ))}
        </Grid>
      </div>
    </Section>
  )
}

export const sCategory = {
  display: 'flex',
  flexShrink: 0,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  fontWeight: 800,
  letterSpacing: '-0.03em',
  cursor: 'pointer',
  transition:
    'color 0.25s ease-out, background-color 0.25s ease-out, filter 0.25s ease-out',
  backgroundColor: colors.text.concat(alpha[8]),
  color: colors.text.concat(alpha[60]),
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.text.concat(alpha[30]),
      color: colors.text.concat(alpha[100]),
    },
  },

  [s.sm_down]: {
    fontSize: '0.75rem',
    margin: '0rem 0.75rem 0.75rem 0rem',
    padding: '0.5rem 1rem',
  },
  [s.md]: {
    fontSize: '0.875rem',
    margin: '0rem 1rem 1rem 0rem',
    padding: '0.75rem 1.5rem',
  },
  active: {
    backgroundColor: colors.brand.concat(alpha[100]),
    color: colors.white.concat(alpha[100]),

    '&:hover': {
      [s.hover]: {
        backgroundColor: colors.brand.concat(alpha[100]),
        color: colors.white.concat(alpha[100]),
        filter: 'brightness(120%)',
      },
    },
  },
}

export const sCategories = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '2rem 0rem',
  [s.sm_down]: {
    margin: '2rem 0rem',
    padding: '0 1rem',
    // overflow: 'scroll',
  },

  flexWrap: 'wrap',

  // '::-webkit-scrollbar': {
  //   width: 0,
  //   height: 0,
  // },
}

// const sDeco = {
//   zIndex: 0,
//   pointerEvents: 'none',
//   position: 'absolute',
//   transform: 'translateX(-1)',
//   float: 'right',
//   svg: { maxWidth: '100vw' },
//   [s.lg]: { transform: 'scale(0.65)', left: 1319, top: -144 },
//   [s.xlg]: {
//     transform: 'scale(0.5)',
//     left: 932,
//     top: -244,
//   },
//   [s.md_down]: { transform: 'scale(0.5)', left: 609, top: -234 },
//   [s.sm_down]: { transform: 'scale(0.5)', left: 932, top: -244 },
//   [s.xs]: { left: 110, top: -300, transform: 'scale(0.7)' },
//   hidder: {
//     position: 'absolute',
//     bottom: 0,
//     right: 0,
//     width: '100%',
//     height: '100%',
//     background: `linear-gradient(#ffffff00, ${colors.white.concat(
//       alpha[100]
//     )} 85%)`,
//   },
// }
export const sBlogWrapper = {
  [s.xs]: { padding: '0rem 0rem 1rem' },
  [s.sm]: { padding: '1rem' },
  [s.md]: { padding: '1rem' },
}

export const sBlog = {
  cursor: 'pointer',

  backgroundColor: colors.greyLighter,
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: '100%',
  borderRadius: 16,
  // transform: 'translate3d(0,0,0)',
  [s.xs]: {
    width: '100%',
  },
  // boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05) ',
      // boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
    },
  },
}

export const sImage = {
  position: 'relative',
  width: '100%',
  height: 0,
  paddingTop: '56.25%',
  borderRadius: '16px 16px 0 0',
  transform: 'translate3d(0,0,0)',
  overflow: 'hidden',

  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    transform: 'translate3d(0,0,0)',
  },
}

export const sTitle = {
  fontSize: '1rem',
  lineHeight: '1.35em',
  letterSpacing: '-0.05em',
  fontWeight: 700,
  margin: '1.5rem 1.5rem 1.5rem',
  overflow: 'hidden',
  // display: '-webkit-box',
  // WebkitLineClamp: 2,
  // WebkitBoxOrient: 'vertical',
}
export const sDescription = {
  lineHeight: '1.35em',
  fontSize: 13,
  letterSpacing: 0,
  maxHeight: 36,
  fontWeight: 400,
  margin: '0rem 1.5rem 1.5rem',
  overflow: 'hidden',
  opacity: 0.6,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: 'none',
}

export default BlogSearch
